import { createApi } from "@reduxjs/toolkit/query/react";
import { GraphQLClient } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import cookieCutter from "cookie-cutter";
import { handleExpiredAccessToken } from "@/lib/handleExpiredAccessToken";
import { handleExpiredRefreshToken } from "@/lib/handleExpiredRefreshToken";

const client = new GraphQLClient(
    `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
    {
        credentials: "include",
        headers: {
            Accept: "application/json",
        },
    }
);

const baseQuery = graphqlRequestBaseQuery({
    client,
    prepareHeaders: (headers) => {
        const token = cookieCutter.get("accessToken");
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    }
});

const enhancedBaseQuery = async (args, api, extraOptions) => {
    try {
        return await baseQuery(args, api, extraOptions);
    } catch (error) {
        if (error.response.status === 401) {
            const parsedError = error.response?.message;
            if (parsedError === "Expired token") {
                const newToken = await handleExpiredAccessToken();
                if (newToken) {
                    const newHeaders = new Headers(args.headers || {});
                    newHeaders.set("Authorization", `Bearer ${newToken}`);
                    return baseQuery({ ...args, headers: newHeaders }, api, extraOptions);
                } else {
                    await handleExpiredRefreshToken();
                    return result;
                }
            } else {
                await handleExpiredRefreshToken();
                return result;
            }
        } else {
            throw error;
        }
    }
};

export const api = createApi({
    baseQuery: enhancedBaseQuery,
    tagTypes: [
        "getCandidates",
        "unlockCandidate",
        "getUserInterviews",
        "getInterviewData",
        "getCandidateInterview",
        "getQuestions",
        "getInterviewTypes",
        "getRequirementsByActiveState",
        "getCurrentUser",
    ],
    endpoints: () => ({}),
});
